import React from "react";

const Footer = () => {
  return (
    <>
      <div className="module-small bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">CONTACT</h5>
                {/* <img  src="assets/logo.svg" alt="mr. Panda" style={{height:"5rem"}}/> */}
                <p>
                  Welcome to the world of OPEX PANDA Fueling Innovation,
                  Igniting Growth - Your Startup's Ultimate Ally!
                </p>
                <p>
                  <i className="fa fa-phone"></i> +91 92387-02982
                </p>
                <p>
                  <i className="fa fa-envelope"> </i>
                  <a href="/#"> opexpanda@gmail.com</a>
                </p>
                <p>
                  <i className="fa fa-envelope"> </i>
                  <a href="/#"> Team@opexpanda.com</a>
                </p>
              </div>
              <div className="widget-social-links">
                <a href="/#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="https://twitter.com/OpeXPanda">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com/in/opex-panda-aa29922ba/">
                  <i className="fa fa-linkedin"></i>
                </a>
                <a href="https://www.instagram.com/panda_opex/">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Services</h5>
                <ul className="icon-list">
                  <li>⁠IT Solutions</li>
                  <li>Graphic Designing</li>
                  <li>Digital Designing</li>
                  <li>Digital Marketing</li>
                  <li>Human Resource Solutions</li>
                  <li>DevOps Solutions</li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Careers</h5>
                <ul className="icon-list">
                  <li>
                    <a href="!#">Software Developement</a>
                  </li>
                  <li>
                    <a href="!#">Recruitment Specialist</a>
                  </li>
                  <li>
                    <a href="!#">Graphic Designer</a>
                  </li>
                  <li>
                    <a href="!#">Business Analyst</a>
                  </li>
                  <li>
                    <a href="!#">Quality Surveyor</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-sm-3">
              <div className="widget">
                <h5 className="widget-title font-alt">Popular Posts</h5>
                <ul className="widget-posts">
                  <li className="clearfix">
                    <div className="widget-posts-image">
                      <a href="#">
                        <img
                          src="assets/images/rp-1.jpg"
                          alt="Post Thumbnail"
                        />
                      </a>
                    </div>
                    <div className="widget-posts-body">
                      <div className="widget-posts-title">
                        <a href="#">Designer Desk Essentials</a>
                      </div>
                      <div className="widget-posts-meta">23 january</div>
                    </div>
                  </li>
                  <li className="clearfix">
                    <div className="widget-posts-image">
                      <a href="#">
                        <img
                          src="assets/images/rp-2.jpg"
                          alt="Post Thumbnail"
                        />
                      </a>
                    </div>
                    <div className="widget-posts-body">
                      <div className="widget-posts-title">
                        <a href="#">Realistic Business Card Mockup</a>
                      </div>
                      <div className="widget-posts-meta">15 February</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="col-sm-3">
              <div>
              <img src="assets/images/PrimaryLogo1.svg" alt="" style={{width:"100%",marginTop:"4rem"}}/>

              </div>
            </div>

          </div>
        </div>
      </div>
      <footer className="footer bg-dark text-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <p className="copyright font-alt">
                Copyright &copy; 2024 &nbsp;
                <a href="index.html">ＯＰΞＸ ＰΛＮＤΛ</a>, All Rights Reserved
              </p>
            </div>
            {/* <div className="col-sm-6">
              <div className="footer-social-links">
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-dribbble"></i>
                </a>
                <a href="#">
                  <i className="fa fa-skype"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
