import React from "react";

const OurFeatures = () => {
  return (
    <>
      <section className="module" id="alt-features">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <h2 className="module-title font-alt">Our features</h2>
              <div className="module-subtitle font-serif">
                Unlocking Innovation: Seamlessly Integrating Full Stack
                Development, Creative Design, and Strategic Marketing Solutions.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-strategy"></span>
                </div>
                <h3 className="alt-features-title font-alt">
                  Full Stack Development
                </h3>
                End-to-end full-stack development for seamless digital solutions
                across platforms.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-tools-2"></span>
                </div>
                <h3 className="alt-features-title font-alt">UI / UX Design</h3>
                Elevating user experiences through intuitive UI/UX design
                solutions tailored to your digital products.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-target"></span>
                </div>
                <h3 className="alt-features-title font-alt">
                  Graphics Designing
                </h3>
                Crafting captivating visual experiences through expert graphic
                design solutions.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-tools"></span>
                </div>
                <h3 className="alt-features-title font-alt">
                  Social Media Marketing
                </h3>
                Boosting brand presence and engagement through strategic social
                media marketing solutions.
              </div>
            </div>
            <div className="col-md-6 col-lg-6 hidden-xs hidden-sm">
              <div className="alt-services-image align-center">
                <img src="assets/images/image_3.svg" alt="Image3" />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3">
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-camera"></span>
                </div>
                <h3 className="alt-features-title font-alt">Cloud Computing</h3>
                Empowering businesses with scalable and efficient cloud
                computing solutions for seamless operations and growth.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-mobile"></span>
                </div>
                <h3 className="alt-features-title font-alt">SEO | SEM</h3>
                Driving online visibility and maximizing results through expert
                SEO and SEM strategies.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-linegraph"></span>
                </div>
                <h3 className="alt-features-title font-alt">
                  Infrastructure Management.
                </h3>
                Efficiently managing your infrastructure to ensure seamless
                operations and optimal performance.
              </div>
              <div className="alt-features-item">
                <div className="alt-features-icon">
                  <span className="icon-basket"></span>
                </div>
                <h3 className="alt-features-title font-alt">
                  Campus Recruitment Drives
                </h3>
                Facilitating successful campus recruitment drives to connect top
                talent with leading opportunities.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurFeatures;
