import React from "react";

const About = () => {
  return (
    <>
    <section className="module" id="about">
            <div className="container">
              <div className="row">
                <div className="col-sm-8 col-sm-offset-2">
                  <h2 className="module-title font-alt">ABOUT US</h2>
                  <div className="module-subtitle font-serif">
                    Welcome to Opexpanda, where creativity meets efficiency!
                    We're not just another company – we're your strategic
                    partner in operation, management, human resource management,
                    event coordination, software engineering, marketing, digital
                    marketing, UX/UI designing, and graphic designing. With a
                    passion for innovation and a commitment to excellence, we
                    turn your visions into reality, one project at a time. Join
                    us on a journey of limitless possibilities, where every idea
                    finds its perfect execution. Welcome to the world of
                    opeXpanda – where your success is our top priority!
                  </div>
                </div>
              </div>
            </div>
          </section>
    </>
  );
};

export default About;
