import React from "react";
import { Routes, Route } from "react-router-dom";
import ROUTES from "./routes";
// import axiosHandler from "../axios/axiosHandler";

const AppRoutes = () => {
  let renderRoutes = ROUTES.map((item, key) =>
    <Route key={key} {...item} />
  );

  console.log("current running route ",renderRoutes)

  return <Routes>{renderRoutes}</Routes>;
};

export default AppRoutes;
// export default axiosHandler(AppRoutes);
