import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

export default (WrappedComponent) => {
  const hocComponent = ({ ...props }) => (
    <div id="wrapper">
      <Header />
      <WrappedComponent {...props} />
      <Footer />
    </div>
  );

  return hocComponent;
};