import React from "react";

const Header = () => {
  return (
    <>
      <div data-spy="scroll" data-target=".onpage-navigation" data-offset="60">
        <nav
          className="navbar navbar-custom navbar-transparent navbar-fixed-top one-page"
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header">
              <button
                className="navbar-toggle"
                type="button"
                data-toggle="collapse"
                data-target="#custom-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="#totop">
                <img className="owl-logo" src="assets/logo.svg" alt="mr. Panda"></img>
              </a>
            </div>
            <div className="collapse navbar-collapse" id="custom-collapse">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a href="#totop">Home</a>
                </li>
                <li><a className="section-scroll" href="#about">About Us</a></li>
                <li>
                  <a className="section-scroll" href="#services">
                    Services
                  </a>
                </li>
                {/* <li><a className="section-scroll" href="#works">Works</a></li> */}
                
                <li>
                  <a className="section-scroll" href="#alt-features">
                    Features
                  </a>
                </li>
                {/* <li><a className="section-scroll" href="#team">Team</a></li>
              <li><a className="section-scroll" href="#pricing">Prices</a></li>
              <li><a className="section-scroll" href="#news">News</a></li> */}
                <li>
                  <a className="section-scroll" href="#contact">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
