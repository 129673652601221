export const BASE_URL_SLUG = "/";
export const API_URL = process.env.REACT_APP_API_URL;
// export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export const toastConfig = {
  position: "top-right",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  toastId: "reactTostify",
};