import PATH from "./path";
import PAGE_KEYS from "./page_key";
import Home from "../components/home/Home";
import LetsConnect from "../components/letConnects/letsConnect";

// pages


const ROUTES = [
  {
    exact: true,
    path: PATH.root,
    Component: Home,
    key: PAGE_KEYS.home
  },
  {
    exact: true,
    path: PATH.home,
    Component: Home,
    key: PAGE_KEYS.home
  },
  {
    exact: true,
    path: PATH.letConnect,
    Component: LetsConnect,
    key: PAGE_KEYS.letConnect
  },
  
];

export default ROUTES;
