import React from "react";

const OurServices = () => {
  return (
    <>
      <section className="module" id="services">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <h2 className="module-title font-alt">Our Services</h2>

              <div className="module-subtitle font-serif">
                Transforming Dreams into Reality: Your Vision, Our Expertise.
              </div>
            </div>
          </div>
          <div className="row multi-columns-row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-icon">
                <span className="icon-strategy"></span>
                </div>
                <h3 className="features-title font-alt">Talent Acquisition</h3>
                <p>
                  Empowering businesses through comprehensive talent
                  acquisition, from sourcing to onboarding, ensuring a skilled
                  and dynamic workforce.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-icon">
                  <span className="icon-mobile"></span>
                </div>
                <h3 className="features-title font-alt">Software Development</h3>
                <p>
                  Delivering cutting-edge software development solutions
                  tailored to meet your business objectives and drive
                  innovation.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-icon">
                  <span className="icon-pictures"></span>
                </div>
                <h3 className="features-title font-alt">
                  Graphics Designing
                </h3>
                <p>
                  Elevating brand presence and engagement with captivating
                  graphics and digital designs crafted to inspire and resonate
                  with your audience.
                </p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-icon">
                  <span className="icon-presentation"></span>
                </div>
                <h3 className="features-title font-alt">Digital Marketing</h3>
                <p>
                  Maximize online visibility and drive growth with strategic
                  digital marketing solutions tailored to your unique goals and target audience.
                </p>
              </div>
            </div>

            {/* <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="features-item">
                <div className="features-icon">
                  <span className="icon-laptop"></span>
                </div>
                <h3 className="features-title font-alt">Website Development</h3>
                <p>
                  Transforming visions into captivating digital experiences
                  through innovative website development solutions.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServices;
