import { toast } from "react-toastify";
import { toastConfig } from "./constants";
import ROUTES from "./routes/routes";
import moment from "moment";

export const toastSuccess = (message) => {
  toast.dismiss();
  toast.success(message, toastConfig);
};

export const toastInfo = (message) => {
  toast.dismiss();
  toast.info(message, toastConfig);
};

export const toastWarning = (message) => {
  toast.dismiss();
  toast.warn(message, toastConfig);
};

export const toastError = (message) => {
  toast.dismiss();
  toast.error(message, toastConfig);
};

export const toastDefault = (message) => {
  toast.dismiss();
  toast(message, toastConfig);
};

export const cleanObject = (obj) => {
  let cleanObj = { ...obj };
  Object.keys(cleanObj).forEach((k) => {
    return !cleanObj[k] && cleanObj[k] !== undefined && delete cleanObj[k];
  });
  return cleanObj;
};

export const getRoutesKeys = () => {
  return [
    ...new Set(ROUTES.filter((item) => item.key).map((item) => item.key)),
  ];
};

export const textViewChecker = (text, altText = "Not available") =>
  text ? text : altText;

export const isoToDate = (date, altText = "-") =>
  date ? moment(date).format("DD/MM/YYYY") : altText;

export const isoToDateTime = (date, altText = "-") =>
  date ? moment(date).calendar() : altText;

export const isoDateTime = (date, altText = "-") =>
  date ? moment(date).format("DD/MM/YYYY, h:mm a") : altText;