import React from "react";
import emailjs from 'emailjs-com';
import { toastError, toastSuccess } from "../../utils";

const LetsConnect = () => {

  const sendEmail = (e) => {
    e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
    emailjs.sendForm('service_td36uit', 'template_1akg3pd', e.target, 'BFMKicGHdYJ8HV2Il')
      .then((result) => {
        toastSuccess('Thank you! We will contact you soon.');
        e.target.reset();
        window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
        toastError('Oops! Something went wrong. Please try again later.');
        console.log(error.text);
      });
  }

  return (
    <>
      <section className="module" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <h2 className="module-title font-alt">Get in touch</h2>
              <div className="module-subtitle font-serif"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3">
              <form
                onSubmit={sendEmail}
              >
                <div className="form-group">
                  <label className="sr-only" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name*"
                    required="required"
                    data-validation-required-message="Please enter your name."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label className="sr-only" htmlFor="email">
                    Email
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Your Email*"
                    required="required"
                    data-validation-required-message="Please enter your email address."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <label className="sr-only" htmlFor="email">
                    Contact Number
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="Number"
                    name="number"
                    placeholder="Your Contact Number"
                    required="required"
                    data-validation-required-message="Please enter your Contact Number."
                  />
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    rows="7"
                    id="message"
                    name="message"
                    placeholder="Your Message*"
                    required="required"
                    data-validation-required-message="Please enter your message."
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-block btn-round btn-d"
                    type="submit"
                    value="Send"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div
                className="ajax-response font-alt"
                id="contactFormResponse"
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LetsConnect;
