import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes";
import { BASE_URL_SLUG } from "./constants";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  return (
    <BrowserRouter basename={BASE_URL_SLUG}>
      <AppRoutes />
      <ToastContainer limit={1} />
    </BrowserRouter>
  );
};

export default App;